@use "sass:math";

@import "../../../assets/stylesheets/_utils";

.footer {
  padding-top: 0;
  padding-bottom: $p4;
  z-index: index($z, footer);
  position: absolute;
  left: 0;
  right: 0;
  background-color: $colorWhite;
  border-top: 1px solid $colorStrokeContent;

  padding-left: $p2;
  padding-right: $p2;

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    display: none;
  }
}

.links {
  padding: $p2 0 0;
  @include fontSubMediumHeading;

  li {
    display: inline-block;
    margin-right: $p2;

    a {
      text-decoration: none;
    }
  }
}

.buttons {
  padding-top: $p2;

  /* SM under */
  @media screen and (max-width: $screen-sm-max) {
    padding-right: 150px;

    > * {
      margin-bottom: $p;
    }
  }

  .storebanner {
    height: 44px;
    width: auto;
    display: inline-block;
    vertical-align: top;
    margin-right: $p;

    img {
      display: block;
      height: 100%;
    }
  }

  .twitter,
  .helpcenter,
  .nowdo {
    display: inline-block;
    padding: math.div($p, 2) $p2 math.div($p, 2) $p;
    @include fontSmallHeading;
    color: $colorWhite;
    text-decoration: none;
    margin-right: $p;
    border-radius: $r;
    min-width: 200px;
    position: relative;

    .icon {
      height: auto;
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.withIcon {
      padding-left: 56px;
    }

    span {
      @include fontSmallHeading;
      display: block;
      cursor: pointer;
      position: relative;
      left: 1px;
    }
    b {
      font-weight: normal;
      position: relative;
      cursor: pointer;
    }
    @mixin color($c) {
      background-color: $c;

      @include hover() {
        background-color: darken($color: $c, $amount: 20%);
      }
    }

    @include color($colorDarkGray);

    &.twitter {
      @include color(#1da1f2);
    }

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      margin-right: 0;
      margin-bottom: $p;
      width: 60vw;

      &.withIcon {
        padding-left: 12vw;
      }

      .icon {
        width: 10vw;
        left: 1vw;
      }
    }
  }
}

.logo {
  .img {
    margin-bottom: $p;
  }
  .tagline {
    @include fontWeightBold;
    @include fontMediumHeading;
    margin-bottom: math.div($p, 2);
  }
  .copy {
    @include fontSmallHeading;
  }

  .svg {
    width: 160px;
    height: auto;

    * {
      fill: $colorBlack;
    }

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      width: 40vw;
    }
  }
}
