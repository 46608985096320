@use "sass:math";

._form {
  .field {
    margin-bottom: $p2;
    width: 100%;
    position: relative;

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      margin-bottom: $p;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .attention {
      font-size: 0.9em;
    }
    .sub {
      margin-top: 0;
    }
    span {
      &.red {
        color: $colorRed;
      }
      &.gray {
        color: $colorGray;
      }
    }
    .add_button {
      padding: math.div($p, 2);
      position: relative;
      top: 4px;
      color: $colorFillLinkText;
      background-position: 0 50%;
      background-repeat: no-repeat;
      background-image: url("/images/_input__add.svg");
      padding-left: 32px;
      @include fontSmall;
      opacity: 1;
      @include transition(opacity);
      @include hover() {
        opacity: 0.7;
      }
    }
  }
  .fields {
    @include flexbox(0);
    margin-bottom: math.div($p, 2);

    &.half {
      width: 50%;

      /* XS under */
      @media screen and (max-width: $screen-xs-max) {
        width: 100%;
      }
    }
    &.short {
      width: 150px;
    }
    &.collapse {
      margin-right: 0;

      input,
      .chosen-choices,
      select,
      textarea {
        border-radius: 0;
        border-right: 1px dashed $colorFillUIMore;
      }
      .field {
        margin-right: 0;
        &:first-child {
          input,
          select,
          textarea {
            border-radius: 4px 0 0 4px;
          }
        }
        &:last-child {
          input,
          .chosen-choices,
          select,
          textarea {
            border-radius: 0 4px 4px 0;
            border-left: none;
            border-right: 0px solid $colorFillUI;
          }
        }
      }
    }
    .field {
      max-width: 20vw;

      /* SM under */
      @media screen and (max-width: $screen-sm-max) {
        max-width: inherit;
      }
    }
  }
  .fields + .sub {
    margin-bottom: $p2;

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      margin-bottom: $p;
    }
  }
  > .sub {
    margin-top: math.div(-$p, 2) !important;
    margin-bottom: $p;
    display: block;
  }
  .sub {
    @include fontSubMediumHeading;
    margin: math.div($p, 2) 0 $p;
    color: $colorFillSubContentText;
    cursor: default;
    margin-top: -$p;

    &.red {
      color: $colorRed;
    }

    &.white {
      color: $colorWhite;
    }

    & + .sub {
      margin-top: math.div(-$p, 2);
    }
  }
  .display {
    @include fontLargeHeading;
    padding: math.div($p, 2) 0;
  }
  .label {
    @include fontMedium;
    display: block;
    margin-bottom: math.div($p, 2);
    cursor: default;
    color: rgba($colorFillContentText, 0.75);
    &.white {
      color: $colorWhite;
    }

    &.inline {
      display: inline-block;
    }
  }
  @mixin width {
    max-width: 40vw;

    /* SM under */
    @media screen and (max-width: $screen-sm-max) {
      max-width: inherit;
    }
    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      min-width: 100%;
    }
  }
  input[type=text],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=url],
  input[type=email],
  input[type=date],
  input[type=time],
  input[type=number],
  input[type=range],
  input[type=color],
  input[type=datetime],
  input[type=datetime-local],
  input[type=month],
  input[type=week],
  &__input,
  .filepond--root,
  .chosen-choices,
  select,
  &__select,
  textarea,
  &__textarea {
    display: block;
    padding: $p $p;
    border: 0px solid $colorStrokeContent;
    box-sizing: border-box;
    min-width: 50%;
    color: $colorFillContentText;
    box-shadow: none;
    background: $colorFillUI;
    outline: none;
    width: 100%;
    @include transition(background);
    @include width;

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      padding: math.div($p, 2);
    }

    &:focus {
    }
    @include hover() {
      background: $colorFillUIMore;
    }

    &.fluid {
      width: 100%;
    }
    &.half {
      width: 50%;
    }
    &.short {
      width: 150px;
    }
    &.inline {
      display: inline-block;
    }
  }
  select,
  &__select {
    cursor: pointer;
    background-size: 16px;
    padding-right: 32px;
    background-position: right 8px top 50%;
    background-repeat: no-repeat;
    min-width: inherit;

    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';

    &::-ms-expand {
      display: none;
    }
  }
  textarea,
  &__textarea {
    min-height: 120px;
    width: 100%;
    resize: vertical;
  }
  input[type=checkbox],
  input[type=radio] {
    cursor: pointer;
    display: inline-block;
    width: auto;
    vertical-align: middle;

    & + label {
      display: inline-block;
    }
  }
  input[type=hidden],
  input[type=file] {
    background-color: $colorFillUI;
    padding: $p;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    @include transition(background-color);

    @include hover() {
      background-color: $colorFillUIMore;
    }
  }
  input[type=submit],
  input[type=reset],
  input[type=button],
  input[type=image],
  button {
    cursor: pointer;
  }
  input[type=search] {
    padding-left:44px;
    background-size: 24px !important;
    background-position: 10px 50% !important;
    background-repeat: no-repeat !important;
    background-image: url(/images/input__search.svg) !important;
  }
  .StripeElement {
    margin-top: $p !important;
  }

  .chosen-container, .search-field {
    width: 100% !important;
  }

  &.inline, &__inline {
    display: inline-block;
    width: auto;
  }
  &.mini, &__mini {
    padding-top: math.div($p, 4);
    padding-bottom: math.div($p, 4);
    @include fontSmall;
    background-size: 12px;
  }
  &.lightgray, &__lightgray {
    border-color: $colorLightgray;
    background-color: transparent;
  }
  .fields .field, .field.fluid {
    input[type=text],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=url],
    input[type=email],
    input[type=date],
    input[type=time],
    input[type=number],
    input[type=range],
    input[type=color],
    input[type=datetime],
    input[type=datetime-local],
    input[type=month],
    input[type=week],
    &__input,
    .chosen-choices,
    select,
    &__select,
    textarea,
    &__textarea {
      width: 100%;
    }
  }
  .field.half {
    input[type=text],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=url],
    input[type=email],
    input[type=date],
    input[type=time],
    input[type=number],
    input[type=range],
    input[type=color],
    input[type=datetime],
    input[type=datetime-local],
    input[type=month],
    input[type=week],
    .filepond--root,
    &__input,
    .chosen-choices,
    select,
    &__select,
    textarea,
    &__textarea {
      width: 50%;
    }
  }
  .field.short {
    input[type=text],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=url],
    input[type=email],
    input[type=date],
    input[type=time],
    input[type=number],
    input[type=range],
    input[type=color],
    input[type=datetime],
    input[type=datetime-local],
    input[type=month],
    input[type=week],
    &__input,
    .filepond--root,
    .chosen-choices,
    select,
    &__select,
    textarea,
    &__textarea {
      width: 150px;
      min-width: 0;
    }
  }

  .subdomain {
    position: relative;
    display: block;

    input {
      padding-left: 60px;
      padding-right: 100px;
    }

    &:before {
      content: 'https://';
      position: absolute;
      left: 8px;
      color: $colorGray;
      top: 8px;
    }
    &:after {
      content: '.nowdo.net';
      position: absolute;
      right: 8px;
      color: $colorGray;
      top: 8px;
    }
  }
  .twitter {
    position: relative;
    display: block;

    input {
      padding-left: 24px;
    }

    &:before {
      content: '@';
      position: absolute;
      left: 8px;
      color: $colorGray;
      top: 8px;
    }
  }
  .colors {
    padding: math.div($p, 2) 0 $p;
    li {
      display: inline-block;
      list-style: none;

      button {
        width: 20px;
        height: 20px;
        text-indent: -9999px;
        border-radius: 10px;
        padding: 0;
      }
    }
  }
  .suggests {
    padding: math.div($p, 2) 0 $p;
    text-align: left;
    li {
      display: inline-block;
      list-style: none;

      button {
        height: 22px;
        border-radius: 2px;
        padding: 0 math.div($p, 2) 0;
        margin-bottom: math.div($p, 4);
      }
    }
  }
  .switch {
    user-select: none;
    input {
      position: absolute;
      left: -9999px;

      &:checked + {
        .positive {
          background-color: $colorGreen;

          & ~ .negative {
            background-color: $colorLightgray !important;
          }
        }
      }

    }
    span {
      padding: math.div($p, 2) $p3;
      color: #fff;
      display: inline-block;
      @include transition(all);
      background-color: $colorLightgray;

      $tr: 4px;

      &.positive {
        border-radius: $tr 0 0 $tr;
      }

      &.negative {
        border-radius: 0 $tr $tr 0;
        margin-left: 2px;
        background-color: $colorRed;
      }
    }
  }
  .thumb {
    position: absolute;
    right: 3px;
    bottom: 3px;
    top: 30px;
    width: 15%;
    max-width: 100px;
    height: auto;
    border: 1px solid $colorLightgray;
    @include imageBackground;
    background-size: contain;
    background-color: #fff;
    border-radius: 4px
  }
  .coupon {
    position: relative;
    display: inline-block;
    max-width: 400px;
    width: inherit;

    .submit {
      position: absolute;
      right: $p;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .checkboxes, .radiobuttons {
    margin: 0 0 $p;

    label {
      display: inline-block;
      margin-right: math.div($p, 2);
      padding: 4px math.div($p, 2);
      border-radius: 4px;
      cursor: pointer;

      /* XS under */
      @media screen and (max-width: $screen-xs-max) {
        display: block;
      }

      input {
        opacity: 0;
        position: absolute;
        &:checked + span {
          background-image: url("/images/_form__radio_on.png");
        }
      }

      span {
        padding-left: 32px;
        background-image: url("/images/_form__radio_off.png");
        background-position: left 50%;
        background-size: 20px;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }

    &.checkboxes {
      input {
        &:checked + span {
          background-image: url("/images/_form__check_on.png");
        }
      }
      span {
        background-image: url("/images/_form__check_off.png");
      }
    }
    &.radiobuttons {
      input {
        &:checked + span {
          background-image: url("/images/_form__radio_on.png");
        }
      }
      span {
        background-image: url("/images/_form__radio_off.png");
      }
    }
    &.bordered {
      margin-top: $p;
      label {
        display: block;
      }
      span {
        border: 1px solid $colorStrokeContent;
        display: block;
        padding-top: math.div($p, 2);
        padding-bottom: math.div($p, 2);
        position: relative;
        background-position: 8px 50%;
        border-radius: $r;

        @include hover() {
          border-color: $colorGray;
          cursor: pointer;

          * {
            cursor: pointer;
          }
        }
      }
      input:checked + span {
        border-color: $colorRed !important;
        background-color: rgba($colorRed, 0.1);
      }
    }
  }
  .filepond {
    &--root {
      @include fontMedium;
    }
    &--drop-label {
      background: $colorFillUI;

      * {
        @include fontMedium;
      }

      @include hover() {
        cursor: pointer;
        background: $colorFillUIMore;

        * {
          cursor: pointer;
        }
      }
    }
  }
  .prices {
    margin-top: $p;
    margin-bottom: 0;

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      display: block;
    }

    .field {
      margin-bottom: 0;
      max-width: 23vw;

      /* SM under */
      @media screen and (max-width: $screen-sm-max) {
        max-width: inherit;
      }
      /* XS under */
      @media screen and (max-width: $screen-xs-max) {
        display: block;
        margin-bottom: $p;
      }
    }

    .box {
      border: 1px solid $colorStrokeContent;
      padding: $p;
      border-radius: $r;
      display: block;
      position: relative;
      border-color: $colorGray;
      @include transition(border-color);

      .title {
        @include fontMediumHeading;
        margin-bottom: math.div($p, 2);
        @include fontWeightBold;
      }
      .price {
        @include fontHugeHeading;
        @include fontHeading;
        @include fontWeightBold;
        color: $colorRed;
        margin-bottom: math.div($p, 2);

        /* XS under */
        @media screen and (max-width: $screen-xs-max) {
          @include fontBigHeading;
        }
      }
      .list {
        color: $colorGray;
        @include fontSubMedium;
      }
      @include hover() {
        cursor: pointer;

        * {
          cursor: pointer;
        }
      }
    }
    input {
      opacity: 0;
      height: 0;
      position: absolute;
    }
    input:checked + .box {
      border-color: $colorRed !important;
    }
  }
  .StripeElement {
    padding: $p 0;

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      padding-top: math.div($p, 2)!important;
      padding-bottom: math.div($p, 2)!important;
      margin-top: 0 !important;
    }
  }
  .stripe {
    padding: math.div($p, 2);
    background-color: $colorFillUI;
    border-radius: $r;

    .StripeElement {
      padding-top: 0;
    }
  }
  ._message {
    @include width;
  }
}

._table {
  width: 100%;
  overflow-x: scroll;

  $g: 8.3%;
  @for $i from 1 through 12 {
    .col-#{$i} {
      width: $g * $i;
      display: table-cell;
      &.border {
        border-right: 1px solid $colorLightgray;
      }
    }
  }

  .sort_link {
    color: $colorGray;
    display: block;
    margin: -$p;
    padding: $p;

    @include hover() {
      color: $colorBlack;
      opacity: 1;
    }

    &.asc, &.desc {
      color: $colorBlack;
    }

    .icon {
      display: inline-block;
      margin-left: 4px;
      height: 16px;
      width: 16px;
      vertical-align: middle;
      position: relative;
      top: -1px;
      background-size: 16px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }

  td, th {
    padding: $p math.div($p, 2);

    &.gray {
      color: $colorGray;
    }
    *:last-child {
      margin-bottom: 0;
    }
  }

  thead {
    tr {
      > * {
        white-space: nowrap;
        position: sticky;
        top: 50px;
        background: $colorFillUI;
        border-bottom: 1px solid $colorFillUIMore;
        box-shadow: 0 1px 0 0 rgba(#fff, 0.3);
        cursor: default;
      }
    }
  }
  tbody {
    th {
      min-width: 120px;
      color: $colorFillSubContentText;
    }
    th, td {
      border-bottom: 1px solid $colorStrokeContent;

      .title {
        font-size: 1.2em;
      }
      a {
        @include hover() {
          text-decoration: underline;
        }
      }
    }
    tr {
      @include hover() {
        background-color: $colorFillUI;
      }
    }
  }

  &.xs_break {
    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      display: block;

      tr, td, th {
        display: block;
      }
      th {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  tr {
    @mixin color($c: $colorBlue) {
      color: $c;
      background-color: rgba($c, 0.1);

      td {
        border-bottom-color: rgba($c, 0.2);
      }

      @include hover() {
        background-color: rgba($c, 0.05);
      }

      select {
        color: $c;
        border-color: $c;
      }
      a {
        color: $c !important;
      }
    }
    &.active {
      @include color($colorGreen);
    }
    &.blue {
      @include color($colorBlue);
    }
    &.draft {
      opacity: 0.2;
    }
    &.closed,
    &.archived,
    &.stage-5,
    &.stage-6 {
      @include color($colorGray);

      @include hover() {
        background-color: rgba($colorGray, 0.2);
      }
    }
  }

  img {
    max-width: 100%;
    max-height: 500px;
  }
}

._button, ._label {
  padding: 10px $p2 12px;
  display: inline-block;
  border-radius: $r5;
  @include fontMediumHeading;
  box-sizing: border-box;
  text-align: center;
  opacity: 1;
  @include transition(all);
  -webkit-appearance: none;
  text-decoration: none;
  cursor: pointer;
  @include fontText;

  * {
    cursor: pointer;
  }

  &._label {
    border-radius: 1px !important;
    cursor: default;

    @include hover() {
      opacity: 1 !important;
    }
  }

  &__wrapper {
    padding: 0 0 math.div($p, 2);
    @include cf;

    ._button {
      margin-right: math.div($p, 2);
      margin-bottom: math.div($p, 2);
    }
    &.center {
      text-align: center;
    }
    &.padded {
      padding: $p2;
    }
    .right {
      float: right;
    }
  }

  @mixin color($c: $colorBlack, $f: #fff) {
    background-color: $c;
    color: $f;
    border: 1px solid $c;

    @include hover() {
      color: $f;
      background-color: darken($c, 20%);
      border-color: darken($c, 20%);
    }

    &:after {
      background-color: $c;
    }
    &.basic {
      border: 1px solid $c;
      background-color: transparent;
      color: $c;

      @include hover() {
        color: $c;
      }
    }
  }

  @include color;

  &.yellow {
    @include color($colorYellow, #000);
  }
  &.blue {
    @include color($colorBlue);
  }
  &.green {
    @include color($colorGreen);
  }
  &.orange {
    @include color($colorOrange);
  }
  &.white {
    @include color(#fff, $colorGray);
  }
  &.skeleton {
    @include color(transparent);
    border: 1px solid $colorWhite;
    text-decoration: none;
    font-weight: bold;

    u {
      text-decoration: none;
    }
  }
  &.red, &.primary {
    @include color($colorRed);

    &.outline {
      @include color($colorWhite, $colorRed);
      border: 1px solid $colorRed;
      text-decoration: none;

      u {
        text-decoration: none;
      }
    }
  }
  &.gray {
    @include color($colorGray);
  }
  &.lightgray {
    @include color($colorLightgray, #000);
  }
  &.discord {
    @include color(#7289DA);
  }
  &.twitter {
    @include color(#1DA1F2);
  }
  &.trans, &.transparent {
    @include color(transparent);
    text-decoration: underline;
  }

  &.mini {
    padding: math.div($p, 4) $p;
    border-radius: 4px;
    @include fontSmallHeading;
  }
  &.small {
    padding: 6px $p;
    border-radius: 4px;
    @include fontSmallHeading;
  }
  &.submedium {
    padding: 8px 16px;
    min-width: 120px;
    @include fontSubMedium;

    span {
      @include fontSmallHeading;
      padding-top: math.div($p, 4);
    }
  }
  &.medium {
    padding: 9px $p4;
    @include fontMedium;

    span {
      @include fontSmallHeading;
      padding-top: math.div($p, 4);
    }
  }
  &.large {
    @include fontLargeHeading;
    padding: $p $p2;

    span {
      @include fontSmallHeading;
      padding-top: math.div($p, 4);
    }
  }
  &.huge {
    @include fontHugeHeading;
    padding: $p $p2;
  }
  &.massive {
    font-size: 44px !important;
    padding: $p $p2;
  }

  &.short {
    min-width: 120px;
  }
  &.fluid {
    width: 100%;
  }
  &.blink {
    position: relative;
    z-index: index($z, ui);

    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-transform: scale(0.5, 0.5);
      transform: scale(0.5, 0.5);
      display: block;
      position: absolute;
      z-index: index($z, ui) - 1;
      content: " ";
      opacity: 0.5;
      border-radius: 10px;
      animation: blink 2s $curveEaseOut 0s infinite normal;
    }
    @include hover() {
      &:after {
        animation: none;
      }
    }
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.3;
    @include hover() {
      opacity: 1;
    }
  }
  &.round {
    border-radius: 32px;
  }
  &.noborder {
    border-color: transparent !important;
  }

  ._loader {
    display: none;
    overflow: hidden;
  }
  &.loading {
    position: relative;
    color: transparent;
    cursor: not-allowed;
    opacity: 0.7;

    ._loader {
      display: block;
      position: absolute;
      top: calc(50% - 0.75rem);
      left: calc(50% - 0.75rem);
    }
  }
  span {
    display: block;
    font-size: 0.8em;
  }

  @keyframes blink {
    0% {
      -webkit-transform: scale(0.8, 0.5);
      transform: scale(0.8, 0.5);
      opacity: 0.7;
    }
    100% {
      -webkit-transform: scale(1.2, 1.8);
      transform: scale(1.2, 1.8);
      opacity: 0;
    }
  }
}

._grid {
  @include _grid;
}

._markdown {
  @include _markdown;
}

._hr {
  border: none;
  border-top: 1px solid $colorStrokeContent;
  padding: 0;
  margin: $p2 0 !important;

  &.withText {
    position: relative;
    height: 1px;
    border-top-style: dashed;

    span {
      @include fontSmallHeading;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $colorWhite;
      display: inline-block;
      padding: 4px 16px;
      @include fontMediumHeading;
    }
  }

  &.padded {
    margin: $p4 0 !important;
  }

  &.thin {
    margin: $p 0 !important;
  }

  &.noborder {
    border-top: none;
  }
}

._only, .only {
  &__pc {

  }
  &__sp {
    display: none !important;
  }

  /* SP only */
  @media screen and (max-width: $screen-xs-max) {
    &__pc {
      display: none !important;
    }
    &__sp {
      display: block !important;
    }
  }

  &__print {
    display: none !important;
  }
  @media print {
    &__web {
      display: none !important;
    }
    &__print {
      display: block !important;
    }
  }
}

._exclude, .exclude {
  &__sm {
    /* SM under */
    @media screen and (max-width: $screen-sm-max) and (min-width: $screen-xs-max) {
      display: none !important;
    }
  }
}

._num {
  width: 30px;
  height: 24px;
  display: inline-block;
  border-radius: 50%;
  color: #fff;
  background-color: $colorDarkGray;
  @include fontMedium;
  line-height: 28px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  cursor: default;
  position: relative;
  top: -2px;
  margin-right: math.div($p, 2);

  &.blue {
    color: #fff;
    background-color: $colorBlue;
  }
  &.red {
    color: #fff;
    background-color: $colorRed;
  }
}

._tab {
  display: inline-block;
  padding: $p $p2;
  text-decoration: none;
  color: $colorFillSubContentText;
  margin-bottom: -1px;

  &__wrapper {
    border-bottom: 1px solid $colorStrokeContent;
  }

  &span {
    cursor: default;
  }
  &a {
    cursor: pointer;
  }

  @include hover() {
    color: $colorBlue;
  }

  &.active {
    cursor: default;
    color: $colorFillContentText;
    border-bottom: 1px solid $colorFillContentText;
  }
}

._message {
  padding: $p;
  border-radius: $r;
  margin-bottom: $p;
  @include fontMedium;

  > *:last-child {
    margin-bottom: 0;
  }
  &__head {
    @include fontLarge;
  }

  @mixin messageColor($c: $colorBlue, $c2: $c) {
    border: 1px solid $c;
    background-color: rgba($c,0.1);
    color: $c2;
  }
  @include messageColor($colorBlue);

  &.red {
    @include messageColor($colorRed);
  }
  &.gray {
    @include messageColor($colorGray, $colorBlack);
  }
}

._fab {
  position: fixed;
  bottom: $p2;
  right: $p2;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $colorFillLinkText;
  z-index: index($z, fab);
  box-shadow: math.div($p, 4) math.div($p, 4) $p rgba($colorBlack, 0.4);

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    bottom: 50px + $p2;
  }

  @keyframes blink_fab {
    0% {
      -webkit-transform: scale(0.5, 0.5);
      transform: scale(0.5, 0.5);
      opacity: 0.7;
    }
    100% {
      -webkit-transform: scale(1.5, 1.5);
      transform: scale(1.5, 1.5);
      opacity: 0;
    }
  }
  &.blink {
    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-transform: scale(0.5, 0.5);
      transform: scale(0.5, 0.5);
      display: block;
      position: absolute;
      z-index: index($z, fab) - 1;
      background-color: $colorFillLinkText;
      content: " ";
      opacity: 0.5;
      border-radius: 50%;
      animation: blink_fab 2s $curveEaseOut 0s infinite normal;
    }
    @include hover() {
      &:after {
        animation: none;
      }
    }
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    * {
      stroke: $colorWhite;
    }
  }
}

._loader,
._loader:after {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
}
._loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.165em solid rgba(255, 255, 255, 0.2);
  border-right: 0.165em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.165em solid rgba(255, 255, 255, 0.2);
  border-left: 0.165em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

._loading_text {
  animation-name: loading_text;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: 5;
  background-repeat: repeat;
  background-size: 100vw;
}

@keyframes loading_text {
  0% {
    color: transparent;
    background-image: url("/images/loading.gif");
  }

  100% {
    color: transparent;
    background-image: url("/images/loading.gif");
  }
}

._labels {
  margin-bottom: $p2;

  p {
    padding: math.div($p, 2) $p;
    text-align: center;
    border-radius: 32px;
    border: 1px solid $colorFillLinkText;
    margin-bottom: math.div($p, 2) !important;

    b {
      color: $colorFillLinkText;
      @include fontWeightBold;
      display: inline-block;
      padding: 0 2px;
    }
  }

  .discord {

  }

  &__wraper {
    margin-top: $p;
  }
}
