@use "sass:math";

@import '../../../assets/stylesheets/_utils';

$headerHeightPC: 64px;
$LogoHeightPC: 30px;
$LogoHeightSP: 25px;
$breakPointIpad: 1024px;

.header {
  display: flex;
  z-index: index($z, header);
  padding: 0 0px;
  border-bottom: 1px solid $colorStrokeContent;
  position: relative;

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: 32px;
  }

  &.withNotice {
  }

  .leftMenu {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;

    @media screen and (max-width: $breakPointIpad) {
      display: none;
    }

    .subnav {
      left: 0;
    }
  }
  .rightMenu {
    display: flex;
    justify-content: flex-end;
    flex-basis: auto;

    @media screen and (max-width: $breakPointIpad) {
      display: none;
    }

    .subnav {
      right: 0;
      .list {
        padding-right: 32px;
      }
    }
  }
  .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000,0.8);
    opacity: 0;
    @include transition(all);

    &.open {
      left: 0;
      opacity: 1;
    }
  }
  .spMenu {
    height: $headerHeightPC;
    display: none;
    justify-content: flex-end;
    flex-basis: auto;
    flex-grow: 1;
    z-index: index($z, fixed_menu);

    @media screen and (max-width: $breakPointIpad) {
      display: flex;
    }

    .menuButton {
      display: flex;
      border-radius: 50%;
      height: 44px;
      width: 44px;
      text-align: center;
      position: fixed;
      right: 16px;
      top: 10px;
      justify-content: center;
      align-items: center;
      background-color: #fff;

      div {
        margin: 0 auto !important;
      }
    }
  }
}
.logo {
  height: $headerHeightPC;
  display: flex;
  z-index: index($z, fixed_menu);

  a {
    height: $headerHeightPC;
    padding: math.div($headerHeightPC - $LogoHeightPC, 2);

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      padding: math.div($headerHeightPC - $LogoHeightSP, 2);
    }

    svg {
      width: auto;
      height: $LogoHeightPC;

      /* XS under */
      @media screen and (max-width: $screen-xs-max) {
        height: $LogoHeightSP;
      }
    }
  }
  @include hover() {
    svg * {
      fill: $colorFillLinkText;
    }
  }
}
.nav {
  display: flex;
  align-items: center;
  .list {
    position: relative;

    .link {
      height: $headerHeightPC;
      line-height: $headerHeightPC;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0 16px;
      cursor: pointer;

      &.active {
        box-shadow: 0 1px 0 $colorFillLinkText;
      }

      .label {
        white-space: nowrap;
        @include fontHeading;
        @include fontText;
        @include fontWeightNormal;
        @include font(15px, 3vw, 1.2);

      }
      .icon {
        display: none;
      }
      .dropdown {
        margin-left: 8px;
        display: flex;
      }
      .profileImage {
        width: 32px;
        height: 40px;
        border-radius: 25px;
        display: block;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .button {
        background-color: $colorFillLinkText;
        color: #fff;
        height: 36px;
        line-height: 36px;
        border-radius: 18px;
        padding: 0 18px;
        @include hover() {
          background-color: darken($colorFillLinkText, 20%);
        }
      }
    }
    .subnav {
      position: absolute;
      top: 100%;
      background-color: #fff;
      box-shadow: 0 5px 5px rgba(#000,0.1);
      z-index: index($z, header);
      display: none;

      .list {
        border-top: 1px solid $colorStrokeContent;
      };
    }

    @include hover() {
      .subnav {
        display: block;
      }
    }
  }
}
.spNav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 85vw;
  background-color: rgba($colorWhite,0.97);
  z-index: index($z, fixed_menu);
  overflow-y: scroll;
  height: 100vh;
  display: block;
  transform: translateX(100%);
  padding: $p5 $p $p5 $p4;
  box-shadow: 0 0 $p rgba(#000,0.1);
  animation: modalwrapperFadeOut 0.5s $curveEaseOut;

  /* XS over(PC only) */
  @media screen and (min-width: $breakPointIpad) {
    display: none !important;
  }

  &.open {
    transform: translateX(0%);
    animation: modalwrapperFadeIn 0.5s $curveEaseOut;
  }

  .section {
    margin-bottom: $p2;

    .list {
      display: block;
      position: relative;

      a {
        text-decoration: none;
        padding: 10px;
        display: block;
      }
      .label {

      }
      .icon {
        position: absolute;
        left: -28px;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;

        img {
          display: block;
          line-height: 1;
        }
      }
    }
  }

  @media screen and (min-width: $breakPointIpad) {
    display: none;
  }
}

@keyframes modalwrapperFadeIn {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
}

@keyframes modalwrapperFadeOut {
    0% {
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      opacity: 0;
      transform: translateX(150%);
    }
}

.bellIcon {
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}

.spBellIcon {
  margin-right: 72px;
}
