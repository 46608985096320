/*  Reset Style
*****************************************/
/*
  http://kurechon.com
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, main,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
* {
  font-size: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}
body {
  line-height: 1;
  position: relative;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main {display:block;}
li {list-style:none;}
*{zoom: 1;}
img {}
html {overflow-y:scroll;}
button,label {cursor: pointer;}
input,textarea,button {outline: none;}

/*  Resets
*****************************************/
/* For modern browsers */
/* Reset input[type="search"] */
form {
  display: block;
  width: 100%;
}
input[type=text],
input[type=password],
input[type=search],
input[type=tel],
input[type=url],
input[type=email],
input[type=date],
input[type=time],
input[type=number],
input[type=range],
input[type=color],
input[type=datetime],
input[type=datetime-local],
input[type=month],
input[type=week],
select,
textarea {
  font-size: 100%;
  -webkit-appearance: none;
  font-family : inherit;
}
input[type=checkbox],
input[type=radio] {
  border: inherit;
  width: inherit;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
}
input[type="radio"] {
  -webkit-appearance: radio;
}
input[type="file"] {
  border: inherit;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}
input[type="search"]:focus {
  outline-offset: -2px;
}
input[type="search"]::-webkit-search-decoration {
  display: none;
}
input[type=hidden],
input[type=file] {

}
input[type=submit],
input[type=reset],
input[type=button],
input[type=image],
button {
  overflow: visible;
  line-height: 1em;
  border:none;
  box-shadow: none;
  padding: none;
  margin: none;
  background: none;
  font-size: 100%;
  -webkit-appearance: none;
}

/* Defaults
*****************************************/
b,strong {
  font-weight: bold;
}

img[src$=".svg"] {
  max-width: 100%;
}

input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}
input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}