@import '../../../assets/stylesheets/_utils';

.form {
  .field {
    margin-bottom: 20px;
    input[type=text], select, textarea {
      max-width: 100%;
    }
  }
}

.inline {
  display: inline-table;
}
