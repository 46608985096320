.classroom-comment-enter {
  opacity: 0;
}
.classroom-comment-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.classroom-comment-exit {
  opacity: 1;
}
.classroom-comment-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
