@use "sass:math";

@import "../../../assets/stylesheets/_utils";

.title {
  @include fontHeading;
  @include fontWeightBold;
  @include font(52px, 9vw, 1.2);
  @include fontRotunda;
  margin-bottom: $p2;
  @include kern;
  letter-spacing: -0.02em;
  position: relative;

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: $p2;
  }

  a {
    @include fontText;
    color: $colorRed;
    @include fontSubMedium;
    text-decoration: none;
    vertical-align: middle;
    margin-left: 32px;
    font-weight: normal;
    font-feature-settings: normal;

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      position: absolute;
      top: 26px;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.sub {
  padding-left: 2px;
  padding-top: math.div($p, 2);
  @include fontHeading;
  @include fontText;
  @include fontWeightBold;
  @include font(16px, 4vw, 1.2);
  color: $colorFillSubContentText;
}
