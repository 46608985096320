@import '../../../assets/stylesheets/_utils';

.body {
  position: relative;
  min-height: 100vh;

  &.noPadding {
    padding: 0 !important;
  }

  &.noMargin {
    margin: 0 !important;
  }
}

.blackLayout {
  background-color: $colorBlack;
  color: $colorWhite;
}
.darkLayout {
  background-color: $colorDark;
  color: $colorWhite;
}
.emptyLayout {
  min-height: 100vh;
}
.withNotice {
  /* XS over(PC only) */
  @media screen and (min-width: $screen-sm-min) {
    // @include transition(all);
    padding-top: $NoticeHeightPC;
  }
}

.onlyContent {
  padding-left: $p;
  padding-right: $p;
}
