// @font-face {
//     font-family: 'Rotunda';
//     font-style: normal;
//     font-weight: 100;
//     src: url('/fonts/Rotunda/Rotunda-Thin.woff2') format('woff2'),
//          url('/fonts/Rotunda/Rotunda-Thin.woff') format('woff');
// }
// @font-face {
//     font-family: 'Rotunda';
//     font-style: normal;
//     font-weight: 200;
//     src: url('/fonts/Rotunda/Rotunda-Light.woff2') format('woff2'),
//          url('/fonts/Rotunda/Rotunda-Light.woff') format('woff');
// }
// @font-face {
//     font-family: 'Rotunda';
//     font-style: normal;
//     font-weight: 300;
//     src: url('/fonts/Rotunda/Rotunda-DemiLight.woff2') format('woff2'),
//          url('/fonts/Rotunda/Rotunda-DemiLight.woff') format('woff');
// }
@font-face {
    font-family: 'Rotunda';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Rotunda/Rotunda-Regular.woff2') format('woff2'),
         url('/fonts/Rotunda/Rotunda--Regular.woff') format('woff');
}
// @font-face {
//     font-family: 'Rotunda';
//     font-style: normal;
//     font-weight: 500;
//     src: url('/fonts/Rotunda/Rotunda-Medium.woff2') format('woff2'),
//          url('/fonts/Rotunda/Rotunda-Medium.woff') format('woff'),
//          ur;
// }

@font-face {
    font-family: 'Rotunda';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/Rotunda/Rotunda-Bold.woff2') format('woff2'),
         url('/fonts/Rotunda/Rotunda-Bold.woff') format('woff');
}
// @font-face {
//     font-family: 'Rotunda';
//     font-style: normal;
//     font-weight: 900;
//     src: url('/fonts/Rotunda/Rotunda-Bold.woff2') format('woff2'),
//          url('/fonts/Rotunda/Rotunda-Bold.woff') format('woff');
// }
