@use "sass:math";

html {
  width: 100%;
  padding: 0;
  touch-action: manipulation;
  background-color: $colorBackgroundAudience;
  color: $colorFillContentText;
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-align: stretch;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  display: -webkit-box;
  text-align: justify;
  word-break: break-all;
  color: $colorFillContentText;
  @include fontMedium;
  @include fontText;
  @include fontWeightNormal;

  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  font-weight: normal;
}

:placeholder-shown {
  color: $colorFillSubContentText;
}
::-webkit-input-placeholder {
  color: $colorFillSubContentText;
}
:-moz-placeholder {
  color: $colorFillSubContentText;
  opacity: 1;
}
::-moz-placeholder {
  color: $colorFillSubContentText;
  opacity: 1;
}
:-ms-input-placeholder {
  color: $colorFillSubContentText !important;
}

::selection {
  background: rgba($colorFillLinkText, 0.9);
  color: #fff;
}
::-moz-selection {
  background: rgba($colorFillLinkText, 0.9);
  color: #fff;
}

a {
  color: $colorFillContentText;
  text-decoration: underline;
  cursor: pointer;

  @include hover() {
    color: $colorFillLinkText;

    * {
      cursor: pointer;
    }
  }
}

p, span, a, li {
  &.red  {
    color: $colorRed;
  }
  &.blue  {
    color: $colorBlue;
  }
  &.green  {
    color: $colorGreen;
  }
  &.gray  {
    color: $colorGray;
  }
  &.yellow  {
    color: $colorYellow;
  }
}
a {
  @include hover() {
    &.red,&.blue, &.green, &.gray, &.yellow {
      opacity: 0.5;
    }
  }
}

.cf {
  @include cf;
}

.smartbanner {
  top: -80px;

  &-android {
    background: #f0f0f0;
    box-shadow: none;
    line-height: 80px;

    .smartbanner-close {
      background-color: transparent;
      box-shadow: none;
      text-shadow: none;
    }

    .smartbanner-info {
      text-shadow: none !important;
      .smartbanner-title {
        @include fontMediumHeading;
        color: $colorFillContentText;
        @include fontHeading;
        @include fontWeightBold;
      }
      .smartbanner-description {
        @include fontSubMediumHeading;
        color: $colorFillSubContentText;
        @include fontHeading;
        @include fontWeightBold;
      }
    }
    .smartbanner-button {
      box-shadow: none;

      .smartbanner-button-text {
        background: $colorFillLinkText !important;
        @include fontSubMediumHeading;
        @include fontText;
        padding: math.div($p, 2) $p;
        border-radius: 40px;
        color: #fff;
        white-space: nowrap;
        font-weight: normal;
      }
    }
  }
}
