@use "sass:math";

._modal {
  position: relative;
  max-width: 400px;
  background-color: $colorWhite;
  left: 50%;
  display: block;
  width: 100%;
  transform: translateX(-50%);
  border-radius: 32px;
  opacity: 1;
  @include transition(min-height);
  color: $colorBlack;

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    border-radius: 0;
  }

  .header, .content, .footer {
    padding: $p;

    &.right {
      text-align: right;

      ._button {
        margin-left: math.div($p, 2);
      }
    }
    &.center {
      text-align: center;
    }
    &.left {
      text-align: left;
    }
  }
  .header_image {
    img {
      width: 100%;
      height: auto;
      display: block;
      border-radius: $r $r 0 0;

      /* XS under */
      @media screen and (max-width: $screen-xs-max) {
        border-radius: 0;
      }
    }
  }
  .header {
    background-color: $colorFillUI;
    border-radius: 32px 32px 0 0;
    @include fontMediumHeading;

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      border-radius: 0;
    }

    & + .content {
      padding-top: $p;
    }
  }
  .content {
    padding-top: $p2;
    @include _markdown($p, 0);

    .textarea {
      width: 100%;
      border: 1px solid $colorStrokeContent;
      border-radius: $r;
      background-color: $colorFillUI;
      padding: math.div($p, 2);
      margin-top: math.div(-$p, 2);
    }

    .event {
      margin-top: -$p;
      min-height: 80px;
      ._labels {
        margin-top: $p;
      }
      &__time {
        display: inline-block;
        vertical-align: top;
      }
      &__archived {
        display: inline-block;
        vertical-align: top;
        padding-top: $p;
        max-width: 50vw;
        h3 {
          display: inline-block;
          @include fontBigHeading;
          @include fontWeightBold;
        }
        p {
          color: $colorFillSubContentText;
          /* XS under */
          @media screen and (max-width: $screen-xs-max) {
            @include fontSmallHeading;
          }
        }
      }
      &__price {
        display: inline-block;
        vertical-align: top;
        padding-top: $p;
        position: relative;
        top: -4px;
        float: right;
        padding-right: $p;

        /* XS under */
        @media screen and (max-width: $screen-xs-max) {
          padding-right: 0;
        }

        .label {
          display: block;
          color: $colorFillSubContentText;
          margin-top: 4px;
        }
        .num {
          @include fontHugeHeading;
          @include fontWeightBold;
          color: $colorFillLinkText;
        }
        .currency {
          @include fontHugeHeading;
          @include fontWeightBold;
          color: $colorFillLinkText;
        }
      }
    }
    ._form:first-child {
      margin-top: 0;
    }
  }
  .footer {
    padding: 16px $p 16px $p;
    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      ._button {
        padding-left: $p;
        padding-right: $p;
      }
    }
  }

  &.wide {
    max-width: 640px;
  }


  &__wrapper {
    position: fixed;
    z-index: index($z, modal);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($colorBlack, 0.9);
    display: none;
    // padding: 20vh $p 20vh;

    .scrollbar-container {
      height: 100%;
      width: 100%;
      padding-top: 10vh;
      padding-bottom: 50vh;
    }

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      padding-left: 0;
      padding-right: 0;
    }

    &.show {
      display: block;
      animation: modalwrapperFadeIn 0.5s $curveEaseOut;

      ._modal {
        animation: modalShow 0.5s $curveEaseOut;
      }
    }
    &.wide ._modal {
      max-width: 640px;
    }
  }
  &__close {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 50%);

    * {
      stroke-width: 1px;
      stroke: $colorWhite;

    }
    @include hover() {
      * {
        stroke: $colorRed;
        cursor: pointer;
      }
    }
}
}

@keyframes modalShow {
    0% {
      transform: translateX(-50%) translateY($p);
    }
    100% {
      transform: translateX(-50%) translateY(0);
    }
}

@keyframes modalwrapperFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
