/*! Yaku Han JP (Gothic - All Include) v3.0.0 SIL by Qrac / Based on Noto Sans CJK JP */

// @font-face {
//   font-family: "YakuHanJP";
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
//   src: url("/fonts/YakuHanJP/YakuHanJP-Thin.eot");
//   src: url("/fonts/YakuHanJP/YakuHanJP-Thin.woff2") format("woff2"),
//   url("/fonts/YakuHanJP/YakuHanJP-Thin.woff") format("woff");
// }
//
// @font-face {
//   font-family: "YakuHanJP";
//   font-style: normal;
//   font-weight: 200;
//   font-display: swap;
//   src: url("/fonts/YakuHanJP/YakuHanJP-Light.eot");
//   src: url("/fonts/YakuHanJP/YakuHanJP-Light.woff2") format("woff2"),
//   url("/fonts/YakuHanJP/YakuHanJP-Light.woff") format("woff");
// }
//
// @font-face {
//   font-family: "YakuHanJP";
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url("/fonts/YakuHanJP/YakuHanJP-DemiLight.eot");
//   src: url("/fonts/YakuHanJP/YakuHanJP-DemiLight.woff2") format("woff2"),
//   url("/fonts/YakuHanJP/YakuHanJP-DemiLight.woff") format("woff");
// }
//
@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/YakuHanJP/YakuHanJP-Regular.eot");
  src: url("/fonts/YakuHanJP/YakuHanJP-Regular.woff2") format("woff2"),
  url("/fonts/YakuHanJP/YakuHanJP-Regular.woff") format("woff");
}
//
// @font-face {
//   font-family: "YakuHanJP";
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url("/fonts/YakuHanJP/YakuHanJP-Medium.eot");
//   src: url("/fonts/YakuHanJP/YakuHanJP-Medium.woff2") format("woff2"),
//   url("/fonts/YakuHanJP/YakuHanJP-Medium.woff") format("woff");
// }
//
@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/YakuHanJP/YakuHanJP-Bold.eot");
  src: url("/fonts/YakuHanJP/YakuHanJP-Bold.woff2") format("woff2"),
  url("/fonts/YakuHanJP/YakuHanJP-Bold.woff") format("woff");
}
@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/fonts/YakuHanJP/YakuHanJP-Black.eot");
  src: url("/fonts/YakuHanJP/YakuHanJP-Black.woff2") format("woff2"),
  url("/fonts/YakuHanJP/YakuHanJP-Black.woff") format("woff");
}
