@import '../../../assets/stylesheets/_utils';

.notice {
  height: $NoticeHeightPC;
  width: 100%;
  z-index: index($z, notice);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $colorWhite;

  animation-name: slideIn;
  animation-duration: 0.2s;
  animation-timing-function: $curveEaseOut;

  @include transition();

  &.blue {
    background-color: rgba($colorBlue, 0.9);
  }

  &.gray {
    background-color: rgba($colorDarkGray, 0.9);
  }

  ._button__wrapper {
    margin-left: 22px;
    padding: 0;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  img {
    display: none;
  }

  .inner {
    display: flex;
    align-items: center;
  }

  .resend {
    color: white;
    border: 1px solid white;
    border-radius: $r;
    padding: 6px 12px;
    margin-left: 10px;
    font-size: 12px;
    display: flex;
    white-space: nowrap;
    line-height: 1.2;

    .loader {
      width: 12px;
      height: 12px;
      margin-right: 6px;
    }
  }

  .text {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: $screen-xs-max) {
    bottom: 64px;
    top: auto;
    justify-content: flex-start;
    padding: 16px $p 0;
    height: auto;
    align-items: start;

    p {
      font-size: 14px;
      line-height: 1.2;
      vertical-align: top;
      width: 100%;
      text-align: left;
      margin-bottom: 14px;
    }

    img {
      display: block;
      position: relative;
      top: 2px;
    }

    .inner {
      margin-left: $p;
      flex-wrap: wrap;
    }

    ._button__wrapper {
      margin: 0;
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
