@charset "utf-8";

@import './_reset';

@import './webfont/_yakuhanjp';
@import './webfont/_rotunda';

@import './_utils';

@import './_base';

@import './global_modules/ui';
@import './global_modules/modal';
@import './global_modules/transition';

@import '../../node_modules/react-perfect-scrollbar/dist/css/styles.css';
@import '../../node_modules/video.js/dist/video-js.css';
@import '../../components/override/Marquee';
@import '../../components/override/simplebar';
